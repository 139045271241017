<template>
  <div class="mess-wrapper">
    <div class="mess-top"></div>
    <div class="mess-main">
      <div class="mess-label">感谢您选择我们的产品</div>
      <div class="mess-con">请留下您的宝贵意见，帮助我们做得更好！</div>
      <div>
        <textarea
          class="mess-txt"
          name=""
          id=""
          cols="30"
          rows="10"
          contenteditable="true"
          v-model="remarkForm.remark"
          placeholder="跟我们说点什么吧（必填）"
        ></textarea>
      </div>
      <div class="mess-label">
        请输入联系信息
        <span>（选填）</span>
      </div>
      <div class="mess-info border-bottom">
        <div class="info-label">联系人</div>
        <input contenteditable="true" style="user-select:text;-webkit-user-select:text !important;" type="text" name="name"  v-model="remarkForm.name">
      </div>
      <div class="mess-info border-bottom">
        <div class="info-label">电话</div>
        <input
          type="number"
          name="phone"
          v-model="remarkForm.phone"
          placeholder="请输入联系方式"
          contenteditable="true" 
          style="user-select:text;-webkit-user-select:text !important;"
        >
      </div>
      <div class="mess-desc">我们将严格保护您的个人隐私</div>
      <div class="mess-btn" @click="handleSub"></div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
const reg = /^1[3456789]\d{9}$/;
export default {
  name: "Message",
  data() {
    return {
      remarkForm: {
        remark: "",
        name: "",
        phone: ""
      },
      nowPhoneHeight: "0",
      hidshow: true, //显示或者隐藏footer,
      isResize: false //默认屏幕高度是否已获取
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
    [Notify.Component.name]: Notify.Component
  },
  mounted() {
    this.defaultPhoneHeight = window.innerHeight;
    window.onresize = () => {
      this.nowPhoneHeight = window.innerHeight;
    };
  },
  watch: {
    nowPhoneHeight: function() {
      if (this.defaultPhoneHeight != this.nowPhoneHeight) {
        //手机键盘被唤起了。
        this.$store.dispatch("setHidShow", false);
      } else {
        //手机键盘被关闭了。
        this.$store.dispatch("setHidShow", true);
      }
    }
  },
  created () {
    // console.log(this.remarkForm)
    this.remarkForm.name = this.userInfo.nickName;
  },
  destroyed() {
    window.onresize = null;
  },
  methods: {
    async handleSub() {
      if (this.remarkForm.remark == "") {
        Notify("请填写意见或者建议");
        return;
      }
      if(this.remarkForm.phone != ""){
        if(!reg.test(this.remarkForm.phone)){
          Notify("请填写正确的手机号！");
          return;
        }
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let par = this.remarkForm;
      let { openId} = this.userInfo;
      par.openId = openId;
      try {
        const {data} = await this.$api.addFeedBack(par);
        if (data.code === 200) {
        // console.log(res);
          this.$toast.success("提交成功，感谢您的建议！");
          this.remarkForm.remark = "";
          // Notify({ type: 'success', message: '提交成功，感谢您的建议！' });
        } else {
          // this.$toast(`${res.data.msg}`);
          Notify({ type: 'warning', message: `${data.msg}` });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mess-wrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  .mess-top {
    width: 100%;
    height: 64px;
    background: url("../assets/images/bg-mess.png") no-repeat;
    background-size: cover;
  }
  .mess-main {
    padding-top: 15px;
    // padding-bottom: 80px;
    width: 340px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .mess-label {
      position: relative;
      padding-left: 12px;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #000000;
      span {
        font-family: PingFangSC-Light;
        font-size: 16px;
        color: #4a4a4a;
      }
    }
    .mess-label::before {
      position: absolute;
      top: -1px;
      left: 0px;
      content: "";
      width: 6px;
      height: 30px;
      background: #f9b23b;
      border-radius: 3px;
    }
    .mess-con {
      padding-left: 12px;
      font-family: PingFangSC-Light;
      font-size: 16px;
      color: #4a4a4a;
      letter-spacing: 0;
      line-height: 32px;
      margin-bottom: 11px;
    }
    .mess-txt {
      -webkit-user-select:text !important;
      margin-bottom: 15px;
      padding: 14px 17px;
      width: 306px;
      height: 163px;
      font-size: 15px;
      background: #ffffff;
      border-radius: 10px;
    }
    .mess-info {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      .info-label {
        width: 94px;
        font-family: PingFangSC-Light;
        font-size: 15px;
        color: #000000;
      }
      input {
        flex: 1;
        height: 20px;
        font-family: PingFangSC-Light;
        font-size: 14px;
        color: #000000;
        background: none;
      }
    }
    .mess-desc {
      margin-top: 7px;
      font-family: PingFangSC-Light;
      font-size: 12px;
      color: #a9a9a9;
    }
  }
  .mess-btn {
    width: 346px;
    height: 89px;
    background: url("../assets/images/btn-mess.png") no-repeat;
    background-size: cover;
  }
}
</style>